
import moment from "moment";
import { getCurrentUser } from "../../../store/selectors/Accounts";
import CryptoJS from 'crypto-js';

//export const IpAddress = "https://vitalszoom.com";
export const IpAddress = "http://47.32.254.89:6001/"
//export const IpAddress = "http://localhost:6001"
export const newIpAddress = "http://34.200.25.17:5000"
// export const AddIpAddress = "https://www.vitalszoom.com";
export const ImplantIP = "https://accessgudid.nlm.nih.gov/";
export const USDATEFORMAT = "MM-dd-yyyy"
export const USCALDATEFORMAT = "mm/dd/yy"
export const SUPERADMINROLEID = 18616
export const EMPLOYERADMINROLEID = 3
export const EMPLOYEEROLEID = 5
const orgData = JSON.parse(window.localStorage.getItem("LOGINDATA") || '{}');
const organization = orgData?.data?.organization;
export const ORGANIZATIONID = organization;
export const LoginData = orgData;
export const LAWFIRMADMINROLEID = 8
export const fileUploadURL = "http://47.32.254.89:5000/"
export const ATTORNEYROLEID = 9
export const ApiKey = `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`;
export const ATTORNEYASSISTANTUSERROLEID = 13
export const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const apiKey = "apikey=97b1c8f0-c95d-11ee-a399-7fb346fa0ff8";
export const curentUser = getCurrentUser();
export const specialCharactor = /[$!&=-]/;
export const numbersOnly = /^[0-9\b]+$/;
export const usPhoneNumberFormat = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
export const emailIdFormat = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/i;


export const audioFileExtension = ["mp3", "mp4", "mp5"];

export enum MhcStatus {
  ERROR = "MHC - 0078",
  SUCCESS = "MHC - 0200",
  ERROR_PATIENT_UPDATE = "MHC - 0027"
}


export const encryptData = (data, key = 'secret key 123') => {
  return CryptoJS.AES.encrypt(data, key).toString();
};


export const decryptData = (encryptedData, key = 'secret key 123') => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};


export function authorize() {
  const jwt = localStorage.getItem('jwt');
  const header1 = { Authorization: 'Bearer ' + jwt }
  return { header1 };
}



export const formatFirstName = (value: any) => {
  const arr = value.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

  }
  const firstName = arr.join(" ");
  return firstName;
}
export const parseDate = (dateString) => {
  return dateString && dateString !== "Invalid date"
    ? new Date(moment(dateString, "YYYYMMDD").format("YYYY-MM-DDTHH:mm:ss.000Z"))
    : null;
};
export const formatSSNumber = (value: any) => {
  if (!value) return value;
  const ssNumber = value.replace(/[^\d]/g, "");
  const ssNumberLength = ssNumber.length;
  if (ssNumberLength < 4) return ssNumber;
  if (ssNumberLength < 6) {
    return `${ssNumber.slice(0, 3)}-${ssNumber.slice(3)}`;
  }
  return `${ssNumber.slice(0, 3)}-${ssNumber.slice(3, 5)}-${ssNumber.slice(5, 9)}`;
}
export const isNumber = (value: number) => {
  const conv = +value;
  if (conv) {
    return true;
  } else {
    return false;
  }
}

export const formatI94Number = (value: any) => {
  if (!value) return value;
  var newvalue = value.split(' ').join('');
  return newvalue.split('').join(' ');
}

export function formatPhoneNumber(value: any) {

  if (!value) return value;
  if (value.charAt(0) === "1" || value.charAt(0) === "0") {
    value = value.slice(1);
  }


  const phoneNumber = value.replace(/[^\d]/g, "");


  const phoneNumberLength = phoneNumber.length;


  if (phoneNumberLength < 4) return phoneNumber;


  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }


  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export const numbersOnlyFormat = (value: any) => {

  if (!value) return value;


  const phoneNumber = value.replace(/[^\d]/g, "");


  const phoneNumberLength = phoneNumber.length;


  if (phoneNumberLength < 4) return phoneNumber;


}





export const formatCardNumber = (value: any) => {

  if (!value) return value;


  const phoneNumber = value.replace(/[^\d]/g, "");


  const phoneNumberLength = phoneNumber.length;


  if (phoneNumberLength < 5) return phoneNumber;

  if (phoneNumberLength < 9) {
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4)}`;
  }

  if (phoneNumberLength < 13) {
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 8)} ${phoneNumber.slice(4)}`;
  }


  return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 8)} ${phoneNumber.slice(8, 12)}  ${phoneNumber.slice(12, 16)}`;
}

export const formatExpireMonth = (value: any) => {

  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");

  const phoneNumberLength = phoneNumber.length;


  if (phoneNumberLength < 3) return phoneNumber;

}
export const formatExpireYear = (value: any) => {

  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");


  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 3) return phoneNumber;

}
export const formatCVC = (value: any) => {

  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");

  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;

}
export const formatNumberOnly = (value: any) => {
  if (!value) return value;
  const pincode = value.replace(/[^\d]/g, "");
  return pincode;

}
export function Constants(): { header1: any; } {
  throw new Error("Function not implemented.");
}

