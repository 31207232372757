import React from "react";
import { Menu } from "primereact/menu";
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { createBrowserHistory } from "history";
import IdleTimer from 'react-idle-timer';
import TimeoutModal from './components/TimeoutModal';
import { HttpLogin } from '../src/utils/Http';
import * as Constants from "../src/containers/pages/Constants/ConstantValues";
import mettlerImage from './assets/images/mettler_images/MettlerImage_Light.svg';
import { Avatar, Badge, IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import ProfileImage from './assets/images/mettler_images/profile.svg';
import changePasswordImage from './assets/images/mettler_images/Change_Password_Image.svg';
import logOutImage from './assets/images/mettler_images/LogoutImage.svg';
import { Box, Dialog, DialogContent, DialogContentText, Typography } from "@mui/material";
import moment from "moment";
import { authorize, MhcStatus } from "../src/containers/pages/Constants/ConstantValues";
import LogoutConfirmationModal from '../src/components/LogOutcomponent/index';
import {  Business, Description, KingBed, People, Person, Settings } from "@mui/icons-material";


interface AppProps {
  onToggleMenu: React.MouseEventHandler;
  isLogged?: boolean;
}

interface AppState {
  items: Array<{}>;
  userName: string;
  userNames: string;
  SystemAdmin: string;
  status: string;
  userfirstName: string;
  userSecondName: string;
  lastName: string;
  middleName: string;
  companyname: string;
  dateofbirth: any;
  role: string;
  userId: string;
  userType: string;
  uploadProfile: string, // Initial state for profile image URL
  inputStaffData: {
    profile: null, // Placeholder; update with actual data
  },
  staffName: string,
  params: string,
  staffid: string,
  anchorEl: any,
  errorMessage: string,
  SuperAdmin: string;
  isAdmin: boolean;
  isSixYearsStayDataDialog: boolean;
  openDialog: boolean;
  addOpenDialog: boolean;
  profileImage: any;
  alertMessage: any;
  showModal: boolean;
  profileOpenDialog: boolean;
  changeStatusDialog: boolean;
  isToggled: boolean;
  endDate: any;
  isOnline: any;
  newStatusData: [];
}
const iPAddress = Constants.IpAddress;
const ddOptions = [
  { name: 'Profile', code: 'PR' },
  { name: 'Logout', code: 'LO' },

];

export class AppTopbar extends React.Component<AppProps, AppState> {
  private menu!: Menu | null;
  private isSixYearsStayDataDialog = false;
  idleTimer: any;
  logoutTimer: any;
  history = createBrowserHistory();
  endDate = new Date();
  constructor(props: AppProps) {
    super(props);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleAddOpenDialog = this.handleAddOpenDialog.bind(this);
    this.handleAddCloseDialog = this.handleAddCloseDialog.bind(this);
    this.handleChangeStatusOpenDialog = this.handleChangeStatusOpenDialog.bind(this);
    this.handleChangeStatusCloseDialog = this.handleChangeStatusCloseDialog.bind(this);
    this.handleProfileOpenDialog = this.handleProfileOpenDialog.bind(this);
    this.handleProfileCloseDialog = this.handleProfileCloseDialog.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleToggleclose = this.handleToggleclose.bind(this);
    this.handleInputUserChange = this.handleInputUserChange.bind(this);
    this.state = {
      isAdmin: false,
      isSixYearsStayDataDialog: false,
      openDialog: false,
      addOpenDialog: false,
      anchorEl: null,
      userName: "",
      userNames: "",
      SystemAdmin: "",
      status: "",
      userId: "",
      staffName: '',
      errorMessage: '',
      companyname: "",
      staffid: "",
      lastName: "",
      params: "",
      middleName: "",
      dateofbirth: "",
      userfirstName: "",
      userSecondName: "",
      role: "",
      userType: "",
      SuperAdmin: "",
      profileImage: "",
      alertMessage: "",
      isOnline: true,
      showModal: false,
      endDate: new Date(),
      changeStatusDialog: false,
      profileOpenDialog: false,
      isToggled: false,
      newStatusData: [],
      uploadProfile: '',
      inputStaffData: {
        profile: null,
      },
      items: [
        {
          icon: "pi pi-fw pi-file",
          items: [
            {
              label1: "",
              icon: "pi pi-fw pi-plus",
              items: [
                {
                  label: "Bookmark",
                  icon: "pi pi-fw pi-bookmark"
                },
                {
                  label: "Video",
                  icon: "pi pi-fw pi-video"
                }
              ]
            },
            {
              label: "",
              icon: "pi pi-fw pi-plus",
              items: [
                {
                  label: "Bookmark",
                  icon: "pi pi-fw pi-bookmark"
                },
                {
                  label: "Video",
                  icon: "pi pi-fw pi-video"
                }
              ]
            },
            {
              label: "",
              icon: "pi pi-fw pi-plus",
              items: [
                {
                  label: "Bookmark",
                  icon: "pi pi-fw pi-bookmark"
                },
                {
                  label: "Video",
                  icon: "pi pi-fw pi-video"
                }
              ]
            },
            {
              label: "",
              icon: "pi pi-fw pi-plus",
              items: [
                {
                  label: "Bookmark",
                  icon: "pi pi-fw pi-bookmark"
                },
                {
                  label: "Video",
                  icon: "pi pi-fw pi-video"
                }
              ]
            }
          ]
        }
      ]

    };
  }
  handleToggle = () => {
    this.setState(prevstate => ({
      isToggled: !prevstate.isToggled,
    }))
  }
  handleToggleclose = () => {
    this.setState({
      isToggled: false
    });

  };
  componentWillMount() {

    const { header1 } = authorize();
    HttpLogin.axios().get("api/dropdowns/get-all", { headers: header1 })
      .then((response) => {
        if (response.data.message.code === MhcStatus.SUCCESS) {
          let arrayNewData = response?.data?.data !== null && response?.data?.data?.length > 0 && response?.data?.data?.filter(k => k.dropdown === "status").map((i) => { return i.list })
          this.setState({ newStatusData: arrayNewData?.[0]?.map(k => { return k.value }) });
        }
      })
    var orgData = JSON.parse(window.localStorage.getItem("LOGINDATA"));
    if (orgData?.data?.userType[0] === "Patient") {
      this.setState({ userName: orgData.data.userDetail.basicDetails[0].name[0].given + " " + orgData.data.userDetail.basicDetails[0].name[0].family });
      var orgDateofBirth = orgData.data.userDetail.basicDetails[0].birthDate;
      var today = new Date();
      var birthDate = new Date(orgDateofBirth);
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      this.setState({ dateofbirth: age_now });
    } else if (orgData?.data?.userType[0] === 'System Admin') {
      this.setState({ userType: orgData.data.userType?.[0] || "" });
      this.setState({ userName: orgData?.data?.userDetail?.pointofcontact?.[0]?.firstName + " " + orgData?.data?.userDetail?.pointofcontact?.[0]?.lastName });
      window.localStorage.setItem("AttendingPhysicians", orgData?.data?.userDetail?.pointofcontact?.[0]?.firstName + " " + orgData?.data?.userDetail?.pointofcontact?.[0]?.lastName);
    } else if (orgData?.data?.userType[0] === 'Super Admin') {
      this.setState({ userType: orgData.data.userType?.[0] || "" });
      this.setState({ userName: orgData?.data?.userDetail?.organizationdetails?.[0]?.name });
      window.localStorage.setItem("AttendingPhysicians", orgData?.data?.userDetail?.organizationdetails?.[0]?.name);
    } else if (orgData?.data?.userType[0] === "Staff" || "Admin") {
      this.setState({ SuperAdmin: orgData?.data?.userDetail?.organizationdetails?.[0]?.name })
      this.setState({ userName: orgData?.data?.userDetail?.name?.[0]?.given + " " + orgData?.data?.userDetail?.name?.[0]?.family || '' });
      window.localStorage.setItem("AttendingPhysicians", orgData?.data?.userDetail?.name?.[0]?.given + " " + orgData?.data?.userDetail?.name?.[0]?.family || '' );
      this.setState({ userType: orgData?.data?.userType?.[0] || "" });
      var orgDateofBirth = orgData?.data?.userDetail?.dateofBirth;
      var today = new Date();
      var birthDate = new Date(moment(orgDateofBirth, "DDMMYYYY").format("YYYY-MM-DDTHH:mm:ss.000Z"));
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      this.setState({ dateofbirth: age_now });
    }
    this.setState({ isOnline: navigator.onLine });

    const onlineHandler = () => {
      this.setState({ isOnline: true });
    }
    const staffid = window.localStorage.getItem('StaffNamefromstaffID');
    const staffName = window.localStorage.getItem('StaffNamefromstaff');

    const params = window.localStorage.getItem('paramsid1')
    this.setState({ params })

    if (staffName || staffid) {
      console.log("StaffName in AppTopbar:", staffName);
      this.setState({ staffName });
      this.setState({ staffid })
    } else {
      console.log("No StaffName found in localStorage");
    }
    const offlineHandler = () => {
      this.setState({ isOnline: false });
    }
    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);


    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };


    // console.log(age_now); 
    //return age_now != 0 && orgDateofBirth != null && orgDateofBirth != "string" ? <span>{age_now}</span>:<span>{rowData.basicDetails[0].birthDate}</span>
  }

  onIdle = () => {
    this.togglePopup();
    this.logoutTimer = setTimeout(() => {
      this.clickLogout();
    }, 1000 * 5 * 1); //
    // 5 seconds
  }

  togglePopup = () => {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  }

  handleStayLoggedIn = () => {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null;
    }
    this.idleTimer.reset();
    this.togglePopup();
  }

  handleLogout = () => {
    this.history.push('/Login');
  }


  clickLogout() {

    const loggedInString = localStorage.getItem("LOGINDATA");
    if (loggedInString) {
      const loggedInData = JSON.parse(loggedInString);
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer my-token',
          'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ username: loggedInData.data.userDetail.username })
      };

      fetch(iPAddress + '/api/signout?jwtToken=' + loggedInData.data.jwt.jwtToken, requestOptions)
        .then(response => response.json())
        .then(json => {
        })
        .then(data => this.setState({ userId: loggedInData.data.userDetail.username }));
    }
    window.localStorage.setItem("AUTHDATA", "");
    window.localStorage.setItem("LOGINDATA", null);
    window.location.href = "/Login";
  }


  fetchProfileImage = () => {
    const { inputStaffData } = this.state;
    var profile = JSON.parse(window.localStorage.getItem("LOGINDATA"));
    const profileImgPath = profile?.data?.userDetail?.profile;

    if (profileImgPath) {
      const imageName = encodeURIComponent(profileImgPath);
      const urlName = '/fileupload/getFileByFilePath?filePath=' + imageName;

      HttpLogin.axios()
        .get(urlName, { responseType: 'blob' })
        .then(response => response.data)
        .then(blob => {
          const url = URL.createObjectURL(blob);
          this.setState({ uploadProfile: url });
          console.log(blob)
        })
        .catch((error) => {
          console.error("Error fetching image:", error);
        })
        .finally(() => {
        });
    }
  };
  newLogout() {
    const loggedInString = localStorage.getItem("LOGINDATA");
    if (loggedInString) {
      const loggedInData = JSON.parse(loggedInString);
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer my-token',
          'My-Custom-Header': 'foobar'
        },
        body: JSON.stringify({ username: loggedInData.data.userDetail.username })
      };

      fetch(iPAddress + '/api/signout?jwtToken=' + loggedInData.data.jwt.jwtToken, requestOptions)
        .then(response => response.json())
        .then(json => {
        })
        .then(data => this.setState({ userId: loggedInData.data.userDetail.username }));
    }
    window.localStorage.removeItem('StaffNamefromstaffID');
    window.localStorage.removeItem('StaffNamefromstaff');
    window.localStorage.removeItem('secretkeyIdEnc');
    localStorage.removeItem('StaffNamefromPD');

    window.location.reload();
    window.localStorage.setItem("PatientHistoryData", null);
    window.localStorage.setItem("PatientHistoryArrayData", null);
    window.localStorage.setItem("AUTHDATA", "");
    window.localStorage.setItem("LOGINDATA", null);
    window.location.href = "/Login";

  }

  handleSubmit() {
    var orgData = JSON.parse(window.localStorage.getItem("LOGINDATA"));
    orgData = orgData.data.userDetail.username;
    var CryptoJS = require("crypto-js");
    var encryptUserName = CryptoJS.AES.encrypt(orgData, 'secret key 123');
    var setEncryptUserName = encodeURIComponent(encryptUserName.toString());
    window.location.href = "/MettlerSetPassword/" + setEncryptUserName;
  }
  clickChange() {
    // alert(JSON.stringify(this.state.userId));
    window.location.href = "/staticui/setpassword";

  }

  myProfileChange() {
    var orgData = JSON.parse(window.localStorage.getItem("LOGINDATA"));
    if (orgData.data.userType[0] === "Staff") {
      window.location.href = "/MettlerStaffInfoPage";
    } else if (orgData.data.userType[0] === "System Admin") {
      window.location.href = "/MettlerOrganizationDetails";
    } else if (orgData.data.userType[0] === "Super Admin") {
      window.location.href = "/MettlerOrganizationDetails";
    } else if (orgData.data.userType[0] === "Admin") {
      window.localStorage.setItem("myProfile", "Admin");
      window.location.href = "/MettlerOrganizationDetails";
    }
  }


  notificationAlert() {
    alert("View Notification");
  }

  messageAlert() {
    alert("View Messages");
  }

  profileAlert() {

  }

  scrollAlert() {
    alert("View Profile Detais");
  }
  showmenu() {

    this.isSixYearsStayDataDialog = true;

  }
  handleOpenDialog() {
    this.setState({
      openDialog: true
    });
  }
  handleCloseDialog() {
    this.setState({
      openDialog: false
    });
  }
  handleAddOpenDialog() {
    this.setState({
      addOpenDialog: true,
    });
  }
  handleAddCloseDialog() {
    this.setState({
      addOpenDialog: false
    });
    this.setState({
      changeStatusDialog: false
    });
  }

  handleProfileCloseDialog = () => {
    this.setState({
      profileOpenDialog: false
    });

  };
  handleProfileOpenDialog() {
    this.setState({
      profileOpenDialog: true
    });
    this.setState({
      openDialog: false
    });
  }
  componentDidMount() {
    // Fetch the profile image when the component mounts
    this.fetchProfileImage();


  }

  handleChangeStatusOpenDialog() {
    this.setState({
      changeStatusDialog: true
    });
    this.setState({
      openDialog: false
    });
  }

  handleChangeStatusCloseDialog() {
    this.setState({
      changeStatusDialog: false
    });
  }

  handlePageChange = () => {
    let accountUser = {
      userId: this.state.userId,
      first_Name: this.state.userName,
      last_Name: this.state.lastName,
      status: "Active"
    };
    const { header1 } = authorize();
    HttpLogin.axios().post('api/updatePersionProfileData', accountUser, { headers: header1 })
      .then((e) => {
        this.setState({ profileOpenDialog: false })
      })
  }

  handleInputUserChange = (property) => {
    this.setState({ userName: property.target.value });
  };

  handleInputLastChange = (property) => {
    this.setState({ lastName: property.target.value });
  };

  getTitle = (path: string) => {
    const titleMap: { [key: string]: string } = {
      "/MettlerStaffInfoPage": "Staff Details",
      "/MettlerAllStaffDetailsList": "Staff Details",
      "/MettlerOrganizationDetails": "Organization",
      "/MettlerOrganizationList": "Organization",
      "/MettlerPatientLoginDashboard": "Patients",
      "/MettlerPatientInfo": "Patients",
      "/MettlerPatientDetails": "Patient Dashboard",
      "/MettlerCreatePatient": "Create Patient",
      "/MettlerStaffPatient": "Patient Staff Assign",
      "/MettlerBedMasterConfiguration": "Bed Master Configuration",
      "/MettlerAdmitPatient": "Patient Visit",
      "/MettlerAdmitPatientupdated": "Patient Visit",
      "/MettlerVisitPatientdata": "Patient Visit",
      "/MettlerDynamicBedAssign": "Patient Bed Assign",
      "/MettlerAdminDashboard": "Configuration Settings",
      "/MettlerConfiguration": "Configuration Settings",
      "/MettlerConfigaddproblem": "Configuration Settings",
      "/MettlerQ15Reports": "Q15 Reports",
      "/MettlerQ15ReportsViews": "Q15 Reports",
      "/MettlerAIMS": "Treatment Forms",
      "/MettlerMTPForm": "Treatment Forms",
      "/MettlerPreAdmitFileUpload": "Pre-Admit File Upload",
      "/MettlerCreatenewschedule": "New Schedule",
      "/MettlerPreAdmitAssessment": "Pre-Admit/Intake Assessment",
      "/MettlerNursetoNurseReport": "Nurse To Nurse Report",
      "/MettlerAscensionVoiceToMp3Convert": "Ascension Voice To Mp3 Convert",
      "/MettlerAscensionFilePatientData": "Ascension File PatientData",
      "/MettlerAscensionFileUpload": "Ascension FileUpload",
      "/mettlerViewFields": "Create New Form",
      "/MettlerAddPatientVitals": "Patient Vital",
      "/MettlerAddMultiVital": "Patient Vital",
      "/MettlerAddPatientProblem": "Patient Problem",
      "/MettlerPatientProblem": "Patient Problem",
      "/MettlerAddAllergy": "Patient Allergy",
      "/MettlerSkinAllergy": "Patient Allergy",
      "/MettlerAddPatientImmunization": "Patient Immunization",
      "/MettlerAddImmunizationView": "Patient Immunization",
      "/MettlerAddProcedure": "Patient Order Procedure",
      "/MettlerBoneMarrowBiopsy": "Patient Order Procedure",
      "/MettlerAddImagingProcedure": "Patient Imaging Procedure",
      "/MettlerCtScan": "Patient Imaging Procedure",
      "/MettlerAddPatientLabTest": "Patient Lab Test",
      "/MettlerAddPatientLabTestview": "Patient Lab Test",
      "/MettlerAddProcedureconsult": "Patient Consult",
      "/MettlerViewProcedure": "Patient Consult",
      "/MettlerDynamicForm": "View Created Form",
      "/MettlerVisitPatientDetails": "Patient Visit(s)",
      "/MettlerStaffAllocation": "Staff Allocation",
    };

    for (const [key, value] of Object.entries(titleMap)) {
      if (path.includes(key)) {
        return value;
      }
    }

    return "Patients";
  };
  getImageSource = (path: string) => {
    if (path.includes("/MettlerBedMasterConfiguration") || path.includes("/MettlerDynamicBedAssign")) {
      return <KingBed />;
    } else if (path.includes("/MettlerOrganizationDetails") || path.includes("/MettlerOrganizationList")) {
      return <Business />;
    } else if (path.includes("/MettlerStaffInfoPage") || path.includes("/MettlerAllStaffDetailsList") ||
      path.includes("/MettlerStaffPatient")) {
      return <People />;
    } else if (path.includes("/MettlerPatientLoginDashboard") || path.includes("/MettlerPatientInfo") ||
      path.includes("/MettlerPatientDetails") ||
      path.includes("/MettlerCreatePatient")) {
      return <Person />;
    } else if (path.includes("/mettlerViewFields") || path.includes("/MettlerDynamicForm")) {
      return <Description />;
    } else if (path.includes("/MettlerAscensionFileUpload") || path.includes("/MettlerAscensionVoiceToMp3Convert") ||
      path.includes("/MettlerAscensionFilePatientData")) {
      return <Description />;
    } else if (path.includes("/MettlerConfiguration") || path.includes("/MettlerAdminDashboard") ||
      path.includes("/MettlerAscensionFilePatientData")) {
      return <Settings />;
    } else {
      return <Description />;
    }
  };
  currentPath = window.location.pathname;


  render() {
    const { isLogged } = this.props;
    const { showModal } = this.state;
    const { uploadProfile } = this.state;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const path = window.location.pathname;
    const title = path.includes(`/MettlerPatientDetails/${this.state.staffid}`)
      ? this.state.staffid
        ? <span style={{ fontSize: "13px", fontWeight: "bold" }}>
          Staff, {this.state?.staffName || "Guest"}
        </span>
        : ``
      : "";
    return (
      <div style={{ background: '#1F489F', WebkitUserSelect: "none" }} className="layout-topbar clearfix">

        <div id="mettlerEmptyPadding" className="p-col-12 p-md-4" style={{ display: "flex" }}>
          <div style={{ width: '20px', height: '20px', position: 'relative', left: '85px', top: '16px' }} >
            {this.getImageSource(this.currentPath)}</div>
          <span className="mettlerTitleText" style={{ fontSize: "16px", paddingLeft: "83px",top:"17px" }}>
            {this.getTitle(this.currentPath)}
          </span>
        </div>
        <div id="mettlerEmptyPadding" className="p-col-12 p-md-1"
          style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
        >
          {title}

        </div>
        <div id="mettlerEmptyPadding" className="p-col-12 p-md-3">
          <img
            style={{
              width: '180px',
              height: '20.973px',
              position: 'relative',
              marginTop: '18px'
            }}
            src={mettlerImage} />
        </div>
        <div id="mettlerEmptyPadding" className="p-col-12 p-md-2"
          style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          <span style={{ fontSize: "13px", fontWeight: "bold" }}>
            Welcome, {this.state?.userName || "Guest"} !
          </span>
          <span style={{ fontSize: "12px" }}>
            {this.state?.userType || "User Type Not Available"}
          </span>
        </div>
        <Box
          id="mettlerEmptyPadding"
          className="p-col-12 p-md-2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >

          <Box sx={{ textAlign: 'center', position: 'relative' }}>
            <IconButton size="small" onClick={() => alert('Notification Alert')} color="inherit">
              <NotificationsIcon sx={{ fontSize: 24 }} />
            </IconButton>
          </Box>

          <Box sx={{ textAlign: 'center', position: 'relative' }}>
            <IconButton size="small" onClick={() => alert('Message Alert')} color="inherit">
              <MailIcon sx={{ fontSize: 24 }} />
            </IconButton>
          </Box>

          <IconButton size="small" color="inherit">
            <SettingsIcon sx={{ fontSize: 24 }} />
          </IconButton>

          <IconButton onClick={this.handleProfileOpenDialog} sx={{ paddingRight: "30px" }}>
            <Badge
              variant="dot"
              overlap="circular"
              color={this.state.isOnline ? 'success' : 'error'}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiBadge-dot': {
                  height: '14px',
                  width: '14px',
                  borderRadius: '50%',
                  border: '2px solid white',
                },
              }}
            >
              <Avatar
                src={uploadProfile}
                alt="Profile"
                sx={{
                  width: 32,
                  height: 32,
                  borderRadius: '50%',
                  bgcolor: 'white',
                  color: 'black',
                }}
              />
            </Badge>
          </IconButton>
        </Box>
        <Dialog open={!this.state.isOnline} >
          <DialogContent sx={{ textAlign: "center" }}>
            <Typography variant="body1" style={{ color: 'red', fontSize: "12px" }}>
              <IconButton onClick={() => { window.location.reload() }} size="small" color="inherit">
                <WifiOffIcon style={{ color: '#ff1744' }} />
              </IconButton>
              You are offline, <br />Please check your internet connection.
            </Typography>
          </DialogContent>
        </Dialog>

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          stopOnIdle={true}
          onIdle={this.onIdle}
          timeout={1000 * 60 * 10}
        />

        <TimeoutModal
          showModal={showModal}
          togglePopup={this.togglePopup}
          handleStayLoggedIn={this.handleStayLoggedIn}
          clickLogout={this.clickLogout}
        />
        <Dialog maxWidth={'md'} PaperProps={{ sx: { overflow: 'hidden', height: '150px', width: '150px', position: 'absolute', top: '6px', right: '-2px' } }}
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
        >
          <DialogContentText >
            <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', left: '18px', top: '18px' }}>
              <div style={{ fontSize: '14px' }} className="AppTopBar-profileName">View</div>
              <div style={{ position: 'relative', top: '15px', fontSize: '14px' }} className="AppTopBar-profileName">Edit</div>
              <a style={{ cursor: 'pointer' }} onClick={this.handleChangeStatusOpenDialog}>
                <div style={{ position: 'relative', top: '30px', fontSize: '14px' }} className="AppTopBar-profileName">Change Status</div></a>
              <div style={{ position: 'relative', top: '45px', fontSize: '14px' }} className="AppTopBar-profileName">Delete</div>
            </div>

          </DialogContentText></Dialog>
        <Dialog maxWidth={'md'} PaperProps={{
          sx: {
            overflow: 'hidden', height: '165px',
            width: '200px', position: 'absolute', top: '6px', right: '-2px'
          }
        }}
          open={this.state.profileOpenDialog}
          onClose={this.handleProfileCloseDialog}
        >
          <DialogContentText >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{
                display: 'flex', flexDirection: 'row', width: "200px", height: '49px',
                flexShrink: '0', background: '#F2F4F8', alignItems: "center",
                justifyContent: "flex-start", paddingLeft: "13px", gap: "20px"
              }}>
                <Avatar
                  src={uploadProfile}
                  alt="Profile"
                  style={{ width: '32px', height: '32px', borderRadius: '32px' }}
                />
                <div style={{ fontSize: "14px", color: "#172B4D" }} className="AppTopBar-profileName">
                  {this.state?.userName}
                  <br /><span style={{ fontSize: "12px", color: "#878C97" }}>
                    {this.state.userType}
                  </span>
                </div>
              </div>
              <a style={{ cursor: 'pointer' }} onClick={this.myProfileChange}>
                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', left: '17px', top: '10px' }}>
                  <img style={{ width: '20px', height: '20px', borderRadius: '32px', opacity: '0.7' }} src={ProfileImage}></img>
                  <div style={{ position: 'relative', left: '19px', top: '1px' }} className="AppTopBar-subHeading-profile">My Profile</div>
                </div>
              </a>
              <a style={{ cursor: 'pointer' }} onClick={this.handleSubmit}><div style={{ display: 'flex', flexDirection: 'row', position: 'relative', left: '17px', top: '25px' }}>
                <img style={{ width: '20px', height: '20px', borderRadius: '32px', opacity: '0.7' }} src={changePasswordImage}></img>
                <div style={{ position: 'relative', left: '19px', top: '1px' }} className="AppTopBar-subHeading-profile">Change Password</div>
              </div>
              </a>
              <a style={{ cursor: 'pointer' }} onClick={() => { this.handleToggle(); this.handleProfileCloseDialog(); }} ><div style={{ display: 'flex', flexDirection: 'row', position: 'relative', left: '17px', top: '40px' }}>
                <img style={{ width: '20px', height: '20px', borderRadius: '32px', opacity: '0.7' }} src={logOutImage}></img>
                <div style={{ position: 'relative', left: '19px', top: '1px' }} className="AppTopBar-subHeading-profile">Log Out</div>
              </div>
              </a>
            </div>
          </DialogContentText>
        </Dialog>
        <LogoutConfirmationModal
          open={this.state.isToggled}
          handleConfirm={this.newLogout}
          handleClose={this.handleToggleclose}
          message="Are you sure you want to logout?"
        />

      </div>
    );
  }
}